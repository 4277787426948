<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs my-3">
            <li
              v-for="(tab, tabIndex) in tabs"
              :key="`key-${tabIndex}`"
              class="nav-item"
            >
              <a
                @click="activeTabIndex = tabIndex"
                :class="activeTabIndex === tabIndex ? 'text-dark active' : 'text-muted'"
                class="nav-link text-capitalize"
                role="button"
              >{{ tab.label }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="activeTabIndex === 0" class="container-fluid">
      <div class="row gx-3">
        <div class="col-12 col-md-9">
          <NutritionForm :userId="userId" class="mb-3"></NutritionForm>
        </div>
        <div class="col-12 col-md-3">
          <NutritionCalculator class="mb-3"></NutritionCalculator>
        </div>
      </div>
    </div>

    <div v-if="activeTabIndex === 1" class="container-fluid">
      <div class="row gx-3">
        <div class="col-12">
          <NutritionHistory :userId="userId" class="mb-3"></NutritionHistory>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nutrition',
  components: {
    NutritionForm: () => import('@/components/nutrition/NutritionForm'),
    NutritionHistory: () => import('@/components/nutrition/NutritionHistory'),
    NutritionCalculator: () => import('@/components/nutrition/NutritionCalculator'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          label: 'Macro Distribution & Weekly Planner',
          icon: 'chart-pie',
        },
        {
          label: 'History',
          icon: 'chart-line',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.side {
  flex: 0 1 33.333%;
  max-width: 400px;
}
</style>
